import { Alert as ChakraAlert } from '@chakra-ui/react';
import React from 'react';

import { useDevWarning } from '../../hooks/useDevWarning';
import {
  AlertCloseButton,
  AlertCloseButtonProps,
} from '../AlertBanner/AlertBanner';
import { AlertContent, AlertContentProps } from '../AlertContent/AlertContent';

import { AlertStatus } from './shared';

type StatusProps =
  | {
      status?: Exclude<AlertStatus, 'neutral'>;
      icon?: never;
    }
  | {
      status: Extract<AlertStatus, 'neutral'>;
      icon?: AlertContentProps['icon'];
    };

type DismissibleProps =
  | {
      onDismiss?: AlertCloseButtonProps['onClose'];
      isDismissible?: true;
    }
  | {
      onDismiss?: never;
      isDismissible?: false;
    };

export type AlertProps = StatusProps &
  DismissibleProps & {
    className?: string;
    children: AlertContentProps['children'];
  };

/**
 * Used for in-page alerts
 */
export const Alert = ({
  status = 'info',
  children,
  icon,
  isDismissible = false,
  onDismiss,
  ...rest
}: AlertProps) => {
  useDevWarning(
    'Alert',
    icon ? status === 'neutral' : true,
    'Custom icons should only be used with the `neutral` status.',
  );

  return (
    <ChakraAlert
      variant="tile"
      data-status={status}
      status={undefined} // We cannot use the `status` prop since we have custom status types
      {...rest}
    >
      <AlertContent status={status} icon={icon}>
        {children}
      </AlertContent>
      {isDismissible && <AlertCloseButton onClose={onDismiss} />}
    </ChakraAlert>
  );
};
